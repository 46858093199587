import axios from "axios";

document.querySelectorAll(".store-positions-info").forEach(el => {
    el.addEventListener("click", e => {
        dataLayer.push({ event: "view-job-click", category: "applicant-journey", action: "click", label: "view-job-details" });
    })
})

/* Job Requisition Search */
document.querySelectorAll("#search_category").forEach(categories => {
    const functions = document.getElementById("search_function")
    let selectedFunction = null
    const params = new URLSearchParams(window.location.search);
    categories.addEventListener("change", async e => {
        const response = await axios.get(`jobs/list-functions/${e.target.value}`)
        for (let i = functions.options.length - 1; i >= 0 ; i--) functions.options.remove(i)
        response.data.forEach(opt => {
            const el = document.createElement('option');
            el.value = opt;
            el.innerHTML = opt;
            functions.appendChild(el);
        })
        if (selectedFunction) functions.value = selectedFunction
        selectedFunction = null
    })
    if (params.has("category")) {
        categories.value = params.get("category")
        // noinspection JSCheckFunctionSignatures
        categories.dispatchEvent(new Event('change'))
        if (params.has("jobfunction")) selectedFunction = params.get("jobfunction")
    }
})

document.querySelectorAll("#load-more-jobs").forEach(button => {
    button.addEventListener("click", async e => {
        const res = await axios.get("/jobs/load-more")
        if (!res.data) return document.getElementById("load-more-jobs").remove()
        document.getElementById("job-list").innerHTML += res.data
    })
})
